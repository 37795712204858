import { GetServerSidePropsContext } from 'next';
import {
  isTypeContactPageTemplate,
  isTypeGenericPageTemplate,
  TypeContactPageTemplate,
  TypeContactPageTemplateSkeleton,
  TypeGenericPageTemplate,
  TypeGenericPageTemplateSkeleton,
  TypePillarSkeleton,
  TypePlainTextPageTemplate,
  TypePlainTextPageTemplateSkeleton,
} from '../../@types/generated';
import { toFormCTASection } from '../common-components/component-library/layout/FormCTA';
import {
  MetaHeaderProps,
  transformMetaHeaderType,
} from '../common-components/contentful-elements/MetaHeader';
import {
  createTemplatePage,
  getLandingPageServerSideProps,
} from '../common-components/contentful-elements/pages/template';
import { TemplatePageProps } from '../common-components/contentful-elements/util/template';
import { getInitiatives, getPillars } from '../utils/api/content';
import { getEntries } from '../utils/api/contentful';
import { UrlError } from '../utils/error';
import { LOCALES } from '../utils/locale';
import { createHighlightImage, createHighlightVideo } from '../utils/templatePages/Highlight';
import { linksToButtons } from '../utils/templatePages/linksToButtons';
import { hasValue } from '../utils/typeCheck';
import withAppProps from '../utils/withAppProps';
import { GenericPageLayout, GenericPageProps } from '../pageLayouts/GenericPageLayout';
import { PlainTextPageLayout, PlainTextPageProps } from '../pageLayouts/PlainTextPageLayout';
import ContactPageLayout, { ContactPageProps } from '../pageLayouts/ContactPageLayout';
import { getLinkHref } from '../common-components/contentful-elements/util/linkHref';

const TopLevelPage = createTemplatePage({ campaignName: 'top-level-page' });

const getOldConfiguration = (ctx: GetServerSidePropsContext) => {
  const { query, locale: language, defaultLocale } = ctx;
  const name: string = query.name as string;
  const locale = LOCALES[language ?? defaultLocale ?? 'en'];
  return {
    slug: name,
    tags: ['Top level'],
    locale,
  };
};

export const getGenericPageServerSideProps = async (
  page: TypeGenericPageTemplate,
  HOST_URL: string,
): Promise<{
  props: GenericPageProps;
  pageAppProps: {
    metaHeader?: MetaHeaderProps;
  };
}> => {
  const { metaHeader, ...fields } = page.fields;

  const categoryIds = (fields.mapAndMetricsImpactFunds ?? [])
    .map((fund) => fund?.sys.id)
    .filter((id) => typeof id === 'string');

  let mapItems: Array<{ label: string; locationCodes: string[] }> = [];
  if (categoryIds.length === 1) {
    // Split map items by pillars if only one category
    const [initiatives, pillars] = await Promise.all([
      getInitiatives({
        categoryId: categoryIds.filter(hasValue),
        status: ['ACTIVE'],
        limit: 100,
        skipContentfulData: true,
      }),
      getPillars({
        categoryId: categoryIds.filter(hasValue),
        skipContentfulData: true,
      }),
    ]);

    const contentfulPillars = await getEntries<TypePillarSkeleton>({
      content_type: 'pillar',
      'sys.id[in]': pillars.result.map((pillar) => pillar.id),
    });

    mapItems = contentfulPillars.items.map((pillar) => {
      const pillarSpecificInitiatives = initiatives.result.filter((initiative) =>
        initiative.pillarIds.includes(pillar.sys.id),
      );

      return {
        label: pillar.fields.title ?? pillar.fields.name,
        locationCodes: pillarSpecificInitiatives.flatMap((initiative) => initiative.locationCodes),
      };
    });
  } else if (categoryIds.length > 1) {
    const impactFunds = (fields.mapAndMetricsImpactFunds ?? []).filter(hasValue);
    const initiativesPerCategory = await Promise.all(
      impactFunds.map((fund) =>
        getInitiatives({
          categoryId: [fund.sys.id],
          status: ['ACTIVE'],
          limit: 100,
          skipContentfulData: true,
        }),
      ),
    );

    mapItems = impactFunds.map((fund, index) => {
      const categorySpecificInitiatives = initiativesPerCategory[index].result ?? [];

      return {
        label: fund.fields.title ?? fund.fields.name ?? '',
        locationCodes: categorySpecificInitiatives.flatMap(
          (initiative) => initiative.locationCodes,
        ),
      };
    });
  }

  const [highlights1videos, highlights2videos, highlights3videos, highlights4videos] =
    await Promise.all([
      createHighlightVideo(fields.highlight1Image),
      createHighlightVideo(fields.highlight2Image),
      createHighlightVideo(fields.highlight3Image),
      createHighlightVideo(fields.highlight4Image),
    ]);

  return {
    props: {
      isGenericPage: true,
      title: fields.heroSectionTitle,
      hero: {
        title: fields.heroSectionTitle,
        paragraph: fields.heroParagraph,
        image: fields.heroImage,
        buttons: fields.heroCTAs?.map((cta) => ({
          text: cta?.fields.identifier,
          url: getLinkHref(cta),
        })),
      },
      highlight1: {
        sectionTitle: fields.highlight1SectionTitle,
        text: fields.highlight1Text,
        buttons: linksToButtons(fields.highlight1Buttons),
        image: createHighlightImage(fields.highlight1Image, fields.highlight1ImageFit),
        landscapeVideo: highlights1videos?.landscapeVideo,
        portraitVideo: highlights1videos?.portraitVideo,
      },
      highlight2: {
        sectionTitle: fields.highlight2SectionTitle,
        text: fields.highlight2Text,
        buttons: linksToButtons(fields.highlight2Buttons),
        image: createHighlightImage(fields.highlight2Image, fields.highlight2ImageFit),
        landscapeVideo: highlights2videos?.landscapeVideo,
        portraitVideo: highlights2videos?.portraitVideo,
      },
      highlight3: {
        sectionTitle: fields.highlight3SectionTitle,
        text: fields.highlight3Text,
        buttons: linksToButtons(fields.highlight3Buttons),
        image: createHighlightImage(fields.highlight3Image, fields.highlight3ImageFit),
        landscapeVideo: highlights3videos?.landscapeVideo,
        portraitVideo: highlights3videos?.portraitVideo,
      },
      highlight4: {
        sectionTitle: fields.highlight4SectionTitle,
        text: fields.highlight4Text,
        buttons: linksToButtons(fields.highlight4Buttons),
        image: createHighlightImage(fields.highlight4Image, fields.highlight4ImageFit),
        landscapeVideo: highlights4videos?.landscapeVideo,
        portraitVideo: highlights4videos?.portraitVideo,
      },
      metrics: {
        title: fields.metricsSectionTitle,
        mapItems,
        values: fields.metricsList?.map((metric) => ({
          metricName: metric?.fields.title,
          metricValue: metric?.fields.metricValue,
          description: metric?.fields.description,
        })),
      },
      downloadForm: {
        sectionTitle: fields.formSectionTitle,
        title: fields.formSectionRichText?.fields.title,
        description: fields.formSectionRichText?.fields.description,
        form: fields.formSectionForm,
      },
      experts: {
        sectionTitle: fields.expertsSectionTitle,
        title: fields.expertsTitle,
        text: fields.expertsText,
        mainExpertsTitle: fields.expertsProfileTitle,
        mainExperts: fields.expertsProfileList?.map((expert) => ({
          name: expert?.fields.name,
          title: expert?.fields.title,
          imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
          imageAlt:
            expert?.fields.cutoutImage?.fields.description ??
            expert?.fields.cutoutImage?.fields.title ??
            '',
          description: expert?.fields.profileDescription,
        })),
        secondaryExpertsTitle: fields.expertsSecondaryProfileTitle,
        secondaryExperts: fields.expertsSecondaryProfileList?.map((expert) => ({
          name: expert?.fields.name,
          title: expert?.fields.title,
          imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
          imageAlt:
            expert?.fields.cutoutImage?.fields.description ??
            expert?.fields.cutoutImage?.fields.title ??
            '',
        })),
      },

      callToAction: {
        sectionTitle: fields.callToActionSectionTitle,
        text: fields.callToActionText,
        buttons: fields.callToActionButtons?.filter(hasValue).map((cta) => ({
          label: cta.fields.identifier,
          href: getLinkHref(cta),
        })),
        backgroundImageUrl: fields.callToActionBackgroundImage?.fields.file?.url,
      },
      partnerLogos: {
        title: fields.partnerLogosTitle,
        logosList: fields.partnerLogosImageLinks?.map((partnerLogo) => ({
          link: getLinkHref(partnerLogo),
          image: {
            url: partnerLogo?.fields?.image?.fields.file?.url ?? '',
            name: partnerLogo?.fields?.image?.fields.file?.fileName,
            alt:
              partnerLogo?.fields.image?.fields.description ??
              partnerLogo?.fields.image?.fields.title ??
              '',
          },
        })),
      },
      contactUs: {
        sectionTitle: fields.contactUsSectionTitle,
        title: fields.contactUsTitle,
        sections: toFormCTASection(fields.contactUsSections),
      },
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({
        metaHeader,
        overrides: {
          canonicalLink: `${HOST_URL}/${fields.slug}`,
        },
      }),
    },
  };
};

export const getPlainTextPageServerSideProps = async ({
  page,
  slug,
  HOST_URL,
}: {
  page: TypePlainTextPageTemplate;
  slug: string;
  HOST_URL?: string;
}): Promise<{
  props: PlainTextPageProps;
  pageAppProps: {
    metaHeader?: MetaHeaderProps;
  };
}> => {
  const { fields } = page;

  return {
    props: {
      isPlainTextPage: true,
      heroSectionTitle: fields.heroSectionTitle,
      heroParagraph: fields.heroParagraph,
      heroCTAs: fields.heroCTAs?.map((cta) => ({
        label: cta?.fields.identifier,
        href: getLinkHref(cta),
      })),
      mainContent: {
        sectionTitle: fields.mainContentSectionTitle,
        text: fields.mainContentText,
      },
      faqSectionTitle: fields.fAQSectionTitle,
      faqTitle: fields.fAQTitle,
      faqPreamble: fields.fAQPreamble,
      faqSectionReferences: fields.fAQSectionReferences?.filter(hasValue).map((faq) => ({
        title: faq?.fields.title,
        description: faq?.fields.description,
      })),
      contactUsSectionTitle: page.fields.contactUsSectionTitle,
      contactUsTitle: page.fields.contactUsTitle,
      contactUsSections: toFormCTASection(fields.contactUsSections),
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({
        metaHeader: page.fields.metaHeader,
        overrides: { canonicalLink: `${HOST_URL}/${slug}` },
      }),
    },
  };
};

export const getContactPageServerSideProps = async (
  page: TypeContactPageTemplate,
): Promise<{
  props: ContactPageProps;
  pageAppProps: {
    metaHeader?: MetaHeaderProps;
  };
}> => {
  return {
    props: {
      isContactPage: true,
      ...page.fields,
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({ metaHeader: page.fields.metaHeader }),
    },
  };
};

export async function getPageProps(
  page: TypeGenericPageTemplate | TypePlainTextPageTemplate | TypeContactPageTemplate,
  HOST_URL: string,
): Promise<{
  props: GenericPageProps | PlainTextPageProps | ContactPageProps;
  pageAppProps: {
    metaHeader?: MetaHeaderProps;
  };
}> {
  if (isTypeGenericPageTemplate(page)) {
    return await getGenericPageServerSideProps(page, HOST_URL);
  } else if (isTypeContactPageTemplate(page)) {
    return await getContactPageServerSideProps(page);
  }

  return await getPlainTextPageServerSideProps({ page, slug: page.fields.slug });
}

export const getServerSideProps = withAppProps<
  TemplatePageProps | GenericPageProps | PlainTextPageProps | ContactPageProps
>(async (context, config) => {
  try {
    const { slug, tags, locale } = getOldConfiguration(context);
    return await getLandingPageServerSideProps({
      slug,
      tags,
      locale,
      context,
      skipErrorLogging: true,
    });
  } catch (e) {
    let { name } = context.query;
    if (!name) throw new Error('No name provided');
    if (Array.isArray(name)) name = name.join('/');

    let page: TypeGenericPageTemplate | TypePlainTextPageTemplate | TypeContactPageTemplate;

    page = await getEntries<TypeGenericPageTemplateSkeleton>({
      content_type: 'genericPageTemplate',
      'fields.slug': name,
      include: 10,
    }).then(({ items }) => items[0]);

    if (!page) {
      page = await getEntries<TypePlainTextPageTemplateSkeleton>({
        content_type: 'plainTextPageTemplate',
        'fields.slug': name,
        include: 10,
      }).then(({ items }) => items[0]);
    }

    if (!page) {
      page = await getEntries<TypeContactPageTemplateSkeleton>({
        content_type: 'contactPageTemplate',
        'fields.slug': name,
        include: 10,
      }).then(({ items }) => items[0]);
    }

    if (!page) {
      throw new UrlError(`No page found for slug ${name}`, { status: 404 });
    }

    return await getPageProps(page, config.HOST_URL);
  }
});

type PageProps = TemplatePageProps | GenericPageProps | PlainTextPageProps | ContactPageProps;

export function isGenericPageProps(props: PageProps): props is GenericPageProps {
  return 'isGenericPage' in props && props?.isGenericPage === true;
}

export function isPlainTextPageProps(props: PageProps): props is PlainTextPageProps {
  return 'isPlainTextPage' in props && props?.isPlainTextPage === true;
}

export function isContactPageProps(props: PageProps): props is ContactPageProps {
  return 'isContactPage' in props && props?.isContactPage === true;
}

export default function Page(props: PageProps) {
  if (isGenericPageProps(props)) {
    return <GenericPageLayout {...props} />;
  } else if (isPlainTextPageProps(props)) {
    return <PlainTextPageLayout {...props} />;
  } else if (isContactPageProps(props)) {
    return <ContactPageLayout {...props} />;
  }

  return <TopLevelPage {...props} />;
}
