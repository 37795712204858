import { GetServerSidePropsContext } from 'next';
import { TypeLandingPage } from '../../../../@types/generated';
import redirects from '../../../../config/redirects';
import { getByUniqueSlug } from '../../../utils/api/contentful';
import withAppProps from '../../../utils/withAppProps';
import { getLandingPageUrl } from '../util/pageUrl';
import { getServerSideProps, getTemplatePage, TemplatePageProps } from '../util/template';
import { captureException } from '../../../utils/tracking/sentry/sentry';
import { MetaHeaderOverrides } from '../MetaHeader';
import { ClientConfig } from '../../../utils/config';

export type Props = TemplatePageProps;
type Configuration<T> = {
  slug: string;
  tags?: string[];
  locale?: string;
  overrides?: Overrides;
  injectedServerSideProps?: T;
};

export const createTemplatePage = <T,>({
  campaignName,
  floatingHeader,
  Component,
}: {
  campaignName?: string;
  floatingHeader?: boolean;
  Component?: ({ serverSideProps }: { serverSideProps: T }) => JSX.Element;
} = {}) => {
  return getTemplatePage({ campaignName, floatingHeader, Component });
};

export const createGetServerSideProps = (slug: string) => {
  return withAppProps<Props>(async (context) => {
    return await getLandingPageServerSideProps({ slug, context });
  });
};

export type Overrides = {
  metaHeader?: MetaHeaderOverrides;
  navigationBar?: {
    textColor?: string;
  };
};

export const createGetDynamicServerSideProps = <T,>(
  getConfiguration: (
    context: GetServerSidePropsContext,
    config: ClientConfig,
  ) => Promise<{
    slug: string;
    tags?: string[];
    locale?: string;
    overrides?: Overrides;
    injectedServerSideProps?: T;
  }>,
) => {
  return withAppProps<Props>(async (context, config) => {
    const { slug, tags, locale, overrides, injectedServerSideProps } = await getConfiguration(
      context,
      config,
    );
    return await getLandingPageServerSideProps<T>({
      slug,
      tags,
      locale,
      context,
      overrides,
      injectedServerSideProps,
    });
  });
};

export const getLandingPageServerSideProps = async <T,>({
  slug,
  tags,
  locale,
  context,
  overrides,
  injectedServerSideProps,
  skipErrorLogging,
}: Configuration<T> & {
  context: GetServerSidePropsContext;
  skipErrorLogging?: boolean;
}) => {
  let page: TypeLandingPage;
  try {
    page = await getByUniqueSlug<TypeLandingPage>({
      contentType: 'landingPage',
      slug,
      tags,
      include: 5,
      locale,
    });
  } catch (error) {
    const hasRedirect = redirects.find(
      (redirect) => redirect.destination.split('/').pop() === slug,
    );

    if (hasRedirect && !skipErrorLogging) {
      captureException(`No contentful page found for redirect to ${slug}`);
    }
    throw error;
  }

  if (page.fields.redirectPage) {
    const redirectUrl = getLandingPageUrl({
      slug: page.fields.redirectPage.fields.slug,
      pageTags: page.fields.redirectPage.fields.tags,
    });

    return {
      redirect: {
        destination: redirectUrl,
        permanent: true,
      },
    };
  }

  return await getServerSideProps<T>({
    page,
    locale,
    context,
    overrides,
    injectedServerSideProps,
  });
};
